@import "partials/bootstrap";
@import "partials/variables";
@import "partials/mixins";
//@import "partials/animations";
//@import "partials/fonts";
@import "partials/masthead";
@import "partials/navbar";
@import "partials/projects";
@import "partials/custom";
@import "partials/swiper";
//@import "ionicons/ionicons";