.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 25rem 0 5rem 0;
   
  //background: linear-gradient(to bottom, #{fade-out($black, .7)} 0%,#{fade-out($black, .3)} 75%,$black 100%), url('../img/bg-masthead.jpg');
//background-color:#cb1418;  
background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    //font-family: 'Varela Round';
    font-size: 1.6rem;
    line-height: 1;
    /*letter-spacing: 0.8rem;*/
    /*background: -webkit-linear-gradient(fade-out(white, 0.1), fade-out(white, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;*/
  }
  h2 {
    //max-width: 20rem;
    //font-size: 1rem;
  }
  @media(min-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media(min-width: 992px) {
    //height: 100vh;
    //padding: 0;
    h1 {
      font-size: 2.5rem;
      line-height: 1;
      /*letter-spacing: 0.8rem;*/
    }
    h2 {
      //max-width: 30rem;
      //font-size: 1.25rem;
    }
  }
}

#autunno2022 {
    background-color:#735448;
}

#estate2022 {
    background-color:#67111c;
}

#millemiglia2022 {
    background-color:#3c352f;
}


#spring2022 {
    background-color:#1b273f;
}

#winter2022 {
    background-color:#dce1dd;
    /*background-image: url(/assets/images/bg-autumn-2021.jpg);*/
    background-repeat: repeat;
    background-size: auto;
}

#autumn2021 {
    background-color:#f0ebe0;
    /*background-image: url(/assets/images/bg-autumn-2021.jpg);*/
    background-repeat: repeat;
    background-size: auto;
}

#summer2021 {
    background-color:#0f5356;
}

#specialedition2021 {
    background-color:#e63812;
}

#spring2021 {
    background-color:#005687;
}

#winter2021 {
    background-color:#5d9093;
}

#autumn2020 {
    background-color:#015188;
}

#summer2020 {
    background-color:#ffffff;
}

#spring2020 {
    background-color:#cb1418;
}

#winter2020 {
    background-color: #635E3C;
}

#autumn2019 {
    background-color: #FEC800;
}
#raccoglitori {
    background-color: #FEC800;
    background-image:url(/assets/images/bg-raccoglitori-2.jpg); 
    background-size:cover;
    background-position: center;
    color:#ffffff;
}

#spilla {
    background-color: #FEC800;
    background-image:url(/assets/images/bg-spilla.jpg); 
    background-size:cover;
    background-position: center;
    color:#ffffff;
}


