/* The custom CSS codes comes here! */




body {
  font-family: $title-font;
  letter-spacing: 0.0625em;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}
.spacing-5 {letter-spacing:.5rem;}


/*

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/
html,
body{
  position: relative;
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6{
  font-family: $title-font;
}

p{
  code{
    background-color: floralwhite;
    padding: 2px 5px;
    border-radius: 3px;
  }
}

.motto{
  font-family: $title-font;
}

.download-btn{
  font-family: $title-font;
  padding: 0px 5px;
  background-color: floralwhite;
  border-radius: 3px;
}

.main-container{}

.main-header{
  min-height: 100px;
  padding: 20px;
  background-color: rgb(200,200,200);
  @include flex;
}

.main-footer{
  min-height: 100px;
  padding: 20px;
  background-color: rgb(200,200,200);
  @include flex;
}

.content{
  padding: 20px;
  background-color: rgb(235,235,235);
}

.sidebar{
  @include flex;
  padding: 20px;
  background-color: rgb(240,240,240);
}


.line-height-1 {
    line-height:1;
}


  .serif-text {
        font-family: $serif-font;
        font-weight: 300;
        font-style: normal;
  }
  .serif-text p { 
      font-size:1.24rem;
  }

  .serif-text-bold {
        font-family: $serif-font;
        font-weight: 700;
        font-style: normal;
  }
  .sans-serif-text {
      font-family: 'Montserrat', sans-serif;
  }

    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      font-size: 1rem;
    }
      
      
      
      .cover {
        -webkit-box-shadow: 0px 0px 25px 7px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 25px 7px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 25px 7px rgba(0,0,0,0.3);
      }
      
      
.viewMore {
  font-weight: 700;
    text-decoration: underline;
    font-size: 1rem;
}

.viewMore[aria-expanded="false"]:before {
  content: "Leggi tutto"; }

.viewMore[aria-expanded="true"]:before {
  content: "- Chiudi"; }
      
h4, .h4 {font-size:1.3rem;}
h2, .h2 {font-size:1.6rem;}

.arrow-down {width:42px;}
      
.text-dark {
    color:#000000!important;
}
.text-light {
    color:#ffffff!important;
}
.bottoni-acquisto {
    width:180px;
}
.logo {
    max-width:80px
  }
      
.btn-buy-header {
    line-height:1; 
    font-weight: 600; 
    border:1px solid #eee;
    font-size:.8rem;
    text-transform: uppercase;
    padding:.5rem;
    border-radius:.3rem;
    color:#ffffff;
}
      .ico-social {width:24px;}
      .footer {font-size:.7rem;}
      .footer a {color:#cccccc}
      
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
.bottoni-acquisto {
        width:180px;
    }
.logo {
    max-width:100px
    }
.btn-buy-header {
    font-size:1rem;
    text-transform: uppercase;
    padding:.8rem;
    border-radius:.3rem;
    color:#ffffff;
    }
    .btn-buy-header:hover {
        color:#ffcc00;
    }    
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
 
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}
