/* Colors */

$black: rgb(0,0,0);
$white: rgb(255,255,255);
$yellow: #FDF050;

$green: #009444;
$light-green: #39B54A;
$dark-green: #006837;

$fushia: #EC008C;
$blue: #0070AC;
$light-blue: #009AEC;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$primary: #081841 !default;


/* Fonts */

$title-font: 'Montserrat', sans-serif;
$serif-font: clarendon-urw, serif;
//$text-font: 'Roboto', sans-serif;